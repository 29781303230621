// import './Spinner.css'; // Import the CSS file

const Spinner = (props) => {

    let className = 'loader';
    if (props.light) {
        className = 'loader loader-light'  
    }
    return (
        <div className={className}></div>
    )
}

export default Spinner;