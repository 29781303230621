import React from "react";

// import About from "../components/About";
import BackToTop from "../components/BackToTop";
// import Blog from "../components/Blog";
// import Contact from "../components/Contact";
// import Footer from "../Layout/Footer";
import FooterAlt from "./FooterAlt";
// import Portfolio from "../components/Portfolio";
// import Services from "../components/Services";
import BWAnalyser from "../modules/BWAnalyser/BWAnalyser";
// import Team from "../components/Team";

const Layout = (props) => {
  return (
    <React.Fragment>
      {props.children}
      <BWAnalyser {...props} />
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
};

export default Layout;
