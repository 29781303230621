
import ParamsForm from "../ParamsForm/ParamsForm";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import Header from "../Header/Header";
import classes from './RegistrationForm.module.css';
import apiGatewayService from "../../common/apiGateway.service";
import Error from "../Error/Error";
import Spinner from "../Spinner/Spinner";
// import logoBlack from  '../../assets/images/BRS_LOGO_FTM_BLACK.png';
import logoBlack from  '../../assets/images/citra-header-logo.png';

const RegistrationForm = (props) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState();
    const [sitesData, setSitesData] = useState([]);
    const [sitesDescription, setsitesDescription] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state
    const [clicked, setClicked] = useState(false);


    
    const fetchFormData = useCallback(async (token, email) => {
        try{
            const response = await apiGatewayService.getFormData(token,email);
            console.log(response);
            setSitesData(response.data.siteMappingsUser);
            setsitesDescription(getsitesDescription(response.data.siteMappingsUser));
            return response;
        }
        catch(error){
            console.log("Error: ", error);
            setError("Internal Server Error. Please Contact Citra Support.");
        }

    }, []);

    useEffect(() => {
        const token = props.auth.getIdToken();
        const email = props.auth.getUserEmail();
        if(token!==null || email!==null){
            fetchFormData(token, email);
        }
        else{
            console.log("Error in Authentication going back to Sign in page");
            console.log(error);
            navigate('/sign-in');
        }

    }, [fetchFormData, error, navigate, props.auth]);


    useEffect(() => {
      let debounceTimer;
      if (clicked) {
        // Set a timeout to reset the clicked state after 1 second
        debounceTimer = setTimeout(() => {
          setClicked(false);
        }, 5000); // Adjust the debounce time as needed (e.g., 1000ms = 1 second)
      }
  
      return () => {
        // Clear the timeout when the component unmounts or when clicked changes
        clearTimeout(debounceTimer);
      };
    }, [clicked]);

    const getsitesDescription = (data)=>{
        return data.map((siteData) => {
            return {
                value: siteData.SITE_DESCRIPTION,
                label: siteData.SITE_DESCRIPTION
            };
        });

    };

    const getSelectedSiteData = (selectedSiteDescription)=>{
        const selectedSiteData = sitesData.find(sitesData =>  sitesData.SITE_DESCRIPTION === selectedSiteDescription);
        return selectedSiteData;
    };

    const checkPhoneNumberIsValid = () =>{
        const regex = /^[0-9]+$/;
        const number = formData['Phone'];
        // if (regex.test(number) && number.length <= 13) {
        if (regex.test(number)) {
          return true;
        } 
        return false;
    }

    const checkFormIsValid = () =>{
        if (formData===undefined || formData['Email'] === '' || formData['Email'] === undefined || formData['First Name'] === '' || formData['First Name'] === undefined || formData['Surname'] === '' || formData['Surname'] === undefined || formData['Phone'] === '' || formData['Phone'] === undefined || formData['Sites'] === '' || formData['Sites'] === undefined) {
            setError('Please enter all fields inside the form');
            console.error('Form Inputs Invalid:', error);
            return false;
        }
        else if(!checkPhoneNumberIsValid()){
            setError('Please enter numbers only for phone field');
            console.error('Form Inputs Invalid:', error);
            return false;
        }
        setError('');
        return true
    }

    const getPostFormData = ()=>{
       const selectSiteData = getSelectedSiteData(formData['Sites']);
       const postData =   {
            FORM_DATA: {
                USER_EMAIL: formData['Email'],
                USER_FIRST_NAME: formData['First Name'],
                USER_SURNAME: formData['Surname'],
                USER_PHONE: formData['Phone'],
                USER_SITE: {
                    ...selectSiteData
                }
            },
        };

        return postData; 
    };


    const postFormData = useCallback( async (token,postData) => {
        try{
            const response = await apiGatewayService.postFormData(token,postData);
            console.log("Posted for successfully: ", response);
        }
        catch(error){
            console.log("Error: ", error);
            setError("Error in submission. Please Contact Citra Support.");
        }
    },[]);

    const handleSubmit = async () => {
        if (!clicked) {
            console.log('Button clicked!');
            setClicked(true);
            try {
                console.log(formData);
                const tokenValue = props.auth.getIdToken();
                const isFormValid = checkFormIsValid();
                if (isFormValid) {
                    const postData = getPostFormData();
                    setLoading(true);
                    await postFormData(tokenValue, postData);
                    setLoading(false);
                    navigate('/');
                    navigate('/thank-you');
                }

            } catch (error) {
                setLoading(false);
                console.error('Error submitting the form:', error);
            }
        } else {
            console.log('Button is clicked already. Waiting for time out.')
        }
    };

    let firstName = '';
    let lastName = '';
    let phone = '';
    let email = props.auth.getUserEmail();
    // let email = '';


    const config = [
        { name: 'Email', type: 'text', value: email },
        { name: 'First Name', type: 'text', value: firstName },
        { name: 'Surname', type: 'text', value: lastName },
        { name: 'Phone', type: 'text', value: phone},
        { name: 'Sites', type: 'react-select', options: sitesDescription },
        // { name: 'Description', type: 'textarea', }
    ];

    const registrationForm = (
        <div className={classes.MainBackground}>
            <div className={classes.RegistrationForm}>
                <Header />
                <div className={classes.Border}>
                    <div className={classes.LogoDiv}>
                        <img className={classes.Logo} src={logoBlack} alt="ao-logo" />
                    </div>
                    <hr></hr>
                    <h2 className={classes.Header}> Enter Details</h2>
                    <ParamsForm
                        data={formData}
                        setData={setFormData}
                        config={config}
                    />
                    <button className={classes.Button} onClick={handleSubmit}> Submit </button>
                </div>
                {loading && <Spinner light />}
                <div className={classes.ErrorDiv}>
                    <Error error={error}></Error>
                </div>
            </div>
        </div>
    );

    return registrationForm;
};

export default RegistrationForm;