import React, { useState, useEffect } from "react";
import { Collapse, Container, NavbarToggler, NavLink } from "reactstrap";
import Scrollspy from "react-scrollspy";

//import images
import logoLight from "../assets/images/logo-light.png";
import logoDark from "../assets/images/logo-dark.png";
import { Link } from "react-router-dom";

const NavBar = ({isDark, cognitoUser, setCognitoUser, isLoggedIn, setIsLoggedIn, handleLogOut}) => {
  const [isOpenMenu, setisOpenMenu] = useState(false);
  const [navClass, setnavClass] = useState("");

  const toggle = () => setisOpenMenu(!isOpenMenu);

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setnavClass("nav-sticky");
    } else {
      setnavClass("");
    }
  }

  const isDarkClass = isDark ? "navbar-light" : "";

//   console.log('isOpenMenu: ', isOpenMenu);
//   console.log('navClass: ', navClass);

  const logoSize = 80;

  return (
    <React.Fragment>
      <span>
        <nav
          className={
            "navbar navbar-expand-lg fixed-top " + 
            // "navbar navbar-expand-lg " + 
            isDarkClass +
            " sticky " +
            navClass
          }
          id="navbar"
        >
          <Container>
            <Link className="navbar-brand logo text-uppercase" to="/">
              {isDark ? (
                <>
                  <img
                    src={logoDark}
                    className="logo-light"
                    alt=""
                    height={logoSize}
                  />
                  <img
                    src={logoDark}
                    className="logo-dark"
                    alt=""
                    height={logoSize}
                  />
                </>
              ) : (
                <>
                  <img
                    src={logoDark}
                    className="logo-dark"
                    alt=""
                    height={logoSize}
                  />
                  <img
                    src={logoLight}
                    className="logo-light"
                    alt=""
                    height={logoSize}
                  />
                </>
              )}
            </Link>
            <NavbarToggler
              className="navbar-toggler"
              type="button"
              onClick={toggle}
            >
              <i className="mdi mdi-menu"></i>
            </NavbarToggler>
            <Collapse
              isOpen={isOpenMenu}
              className="navbar-collapse"
              id="navbarNav"
            >
              <Scrollspy
                offset={-18}
                items={[
                  "home",
                  "bwanalyser",
                ]}
                currentClassName="active"
                className="navbar-nav ms-auto navbar-center"
                id="mySidenav"
              >
                {isLoggedIn && cognitoUser && <li className="nav-item"><NavLink href='#'>{`${cognitoUser?.signInUserSession?.accessToken?.payload?.["cognito:groups"]?.[0]}`}</NavLink></li>}
                {/* {isLoggedIn && cognitoUser && <label className={classes.UserName}>{`${cognitoUser.signInUserSession.accessToken.payload["cognito:groups"][0]}`}</label>} */}

                <li className="nav-item">
                    <Link className="nav-link" to="https://citras.io">Home</Link>
                </li>
                
                {/* <li className="nav-item">
                  <NavLink href="/#bwanalyser">Get Started</NavLink>
                </li> */}

                {isLoggedIn && <li onClick={handleLogOut} className="nav-item"><NavLink href='/'>Log Out</NavLink></li>}
                {/* {isLoggedIn && <button className={classes.LoginButton} onClick={handleLogOut}> LogOut</button>} */}


                {!isLoggedIn && <li className="nav-item"><NavLink href='/sign-up'>Sign Up</NavLink></li>}
                {/* {!isLoggedIn && <button className={classes.LoginButton} onClick={handleSignUp}> SignUp </button>} */}
                
                {!isLoggedIn && <li className="nav-item"><NavLink href='/sign-in'>Log In</NavLink></li>}
                {/* {!isLoggedIn && <button className={classes.LoginButton} onClick={handleLogin}> Log In </button>}                 */}
              </Scrollspy>
            </Collapse>
          </Container>
        </nav>
      </span>
    </React.Fragment>
  );
};

export default NavBar;
