import React from "react";
import NavBar from "../../Layout/Navbar";
// import Section from "./Section";
import HeaderSection from "../../components/HeaderSection";

const Layout1 = (props) => {
  return (
    <React.Fragment>
      <NavBar {...props} isDark={false} />
      {/* import Section */}
      {/* <Section /> */}
      <HeaderSection 
            title='Modernise your SAP BW System with Gen AI'
            subtitle="Citra Solutions BW Analyser leverages advanced Gen AI to analyse your SAP BW system & generate a comprehensive report to facilitate your transition to a modern data platform."
            mode='home'
            {...props} 
        />      
    </React.Fragment>
  );
};

export default Layout1;
