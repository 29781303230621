import React from "react";
import RouteIndex from "./Routes/RouteIndex";

//import Custom Style scss
import "./assets/scss/theme.scss";

// import app.css, just for root vars used in css modules from components imported from existing bw analyser app - all other components styled using scss style sheets imported above
import './App.css';

function App() {
  return (
    <React.Fragment>
      <RouteIndex />
    </React.Fragment>
  );
}

export default App;
