import React from "react";
import NavBar from "../../Layout/Navbar";
// import Section from "./Section";
import HeaderSection from "../../components/HeaderSection";

const NavWrapper = (props) => {
  return (
    <React.Fragment>
      <NavBar isDark={false} />
      <HeaderSection   title={props.title} />      
      {props.children}
    </React.Fragment>
  );
};

export default NavWrapper;


