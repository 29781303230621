import React, {useState} from "react";
// import routes from "./allRoutes";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";

import Layout1 from "../pages/Layout1/Layout1"
// import BlogPost from "../pages/BlogPost/BlogPost";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Terms from "../pages/Terms/Terms";

// import modules from original react app
import authService from '../common/auth.service';
import SignUp from '../modules/SignUp/SignUp';
import SignIn from '../modules/SignIn/SignIn';
import VerifyCode from '../modules/VerifyCode/VerifyCode';
import RegistrationForm from '../modules/RegistrationForm/RegistrationForm';
import ThankYou from '../modules/ThankYou/ThankYou';
import Form from '../modules/Form/Form';
// import BWAnalyzer from '../modules/BWAnalyser/BWAnalyser';
import Attachments from '../modules/Attachments/Attachments';
import NavWrapper from "../pages/NavWrapper/NavWrapper";
import localStorageService from '../common/localStorage.service';

const RouteIndex = () => {

    // state needed in multiple levels of components (i.e. BWAnalyser but also Nav)
    const [cognitoUser, setCognitoUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] =  useState(false);
    const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
    const [isUploadFiles, setIsUploadFiles] = useState(false);    

    const navigate = useNavigate();
    const auth = authService;
    console.log(auth);

    console.log('[RouteIndex] - component rendered');


    const setAllOptionFalse = () =>{
        setIsDownloadEnabled(false);
        setIsUploadFiles(false);
    };


    // const handleLogin = () => {
    //     navigate('/sign-in');
    // };

    // const handleSignUp = () => {
    //     navigate('/sign-up');
    // };

    const handleLogOut = () => {
        localStorageService.removeObject('user');
        setIsLoggedIn(false);
        setAllOptionFalse();
        setCognitoUser(null);
        // navigate('/sign-in'); // was here to force a re-render when running within the /bw-analyser route, no longer needed as running from higher level component
        setTimeout(()=>{
            navigate('/');
        },50);
    };

    const loginProps = {
        cognitoUser: cognitoUser,
        setCognitoUser: setCognitoUser,
        isLoggedIn: isLoggedIn,
        setIsLoggedIn: setIsLoggedIn,
        // handleLogin: handleLogin,
        // handleSignUp: handleSignUp,
        handleLogOut: handleLogOut,
        isDownloadEnabled: isDownloadEnabled,
        setIsDownloadEnabled: setIsDownloadEnabled,
        isUploadFiles: isUploadFiles,
        setIsUploadFiles: setIsUploadFiles,
        setAllOptionFalse: setAllOptionFalse
    }

  return (
    <React.Fragment>
      <Routes>
        <Route path="/bw-analyser" element={<Navigate to="/" replace />}/>     
        <Route path='/'  element={<Layout {...loginProps}> <Layout1 {...loginProps} /></Layout>}></Route>
        <Route path='/privacy-policy'  element={<PrivacyPolicy />}></Route>
        <Route path='/terms'  element={<Terms />}></Route>
        <Route path="/sign-in" element={<NavWrapper ><SignIn auth={auth} /></NavWrapper>} />
        <Route path="/sign-up" element={<NavWrapper ><SignUp auth={auth} /></NavWrapper>} />
        <Route path="/enter-secret-code" element={<NavWrapper ><VerifyCode auth={auth} /></NavWrapper>} />
        <Route path="/registration-form" element={<RegistrationForm auth={auth} />} />
        <Route path="/thank-you" element={<ThankYou auth={auth} />} />
        <Route path="/register" element={<Form auth = {auth} />} />
        <Route path="/attach" element={<Attachments auth = {auth} />} />        
      </Routes>
    </React.Fragment>
  );
};

export default RouteIndex;



// orignial template code, this maps all routes from allRoutes.js but that's mostly to show each of the 6 template options with the same content - changing to fixed routes for now since we don't want to show the same sections on every page
// const Index = () => {
//   return (
//     <React.Fragment>
//       <Routes>
//         <Route>
//           {routes.map((route, idx) => (
//             <Route path={route.path} element={
//               <Layout>
//                 {route.component}
//               </Layout>
//             } key={idx} />
//           ))}
//         </Route>
//         {/* <Route path='/blog' element={<Layout></Layout>}></Route> */}
//       </Routes>
//     </React.Fragment>
//   );
// };